import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import { Link } from "gatsby";

const Portfolio = ({ data, location }) => {
  const [posts, setPost] = useState(data.allMarkdownRemark.nodes);
  const [category, setCategory] = useState("");
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category");
    setCategory(category);
    if (!category) {
      setPost(data.allMarkdownRemark.nodes);
    }
    if (category) {
      let post = data.allMarkdownRemark.nodes.filter((data) => {
        return data.frontmatter.category === category;
      });
      setPost(post);
    }
  }, [location, data, category]);

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle.toString()}>
        <Seo title="Portfolio" />
        <section
          style={{ minHeight: "90vh" }}
          className="blog-listing gray-bg mt-5"
        >
          <div className="container">
            <div className="row justify-content-center align-item-center text-center">
              <h5 style={{ lineHeight: "2em" }}>No portfolios found.</h5>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
  return (
    <Layout location={location} title={siteTitle.toString()}>
      <Seo title="Portfolio" />
      <section className="blog-listing gray-bg">
        <div className="container">
          {/* section title */}
          <div className="row justify-content-md-center">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <div className="section-title text-center title-ex1">
                <h2>My Work</h2>
                <p></p>
              </div>
            </div>
          </div>
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li
              role="presentation"
              className={`${!category ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${!category ? "active show" : "show"}`}
                to="/portfolio"
                aria-controls="all"
                role="tab"
                data-toggle="tab"
                aria-selected="true"
              >
                All
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "nodejs" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "nodejs" ? "active show" : "show"}`}
                to="/portfolio/?category=nodejs"
                aria-controls="nodejs"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Node JS
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "docker" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "docker" ? "active show" : "show"}`}
                to="/portfolio/?category=docker"
                aria-controls="nodejs"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Docker
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "laravel" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "laravel" ? "active show" : "show"}`}
                to="/portfolio/?category=laravel"
                aria-controls="laravel"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Laravel
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "react" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "react" ? "active show" : "show"}`}
                to="/portfolio/?category=react"
                aria-controls="react"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                React
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "angular" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "angular" ? "active show" : "show"}`}
                to="/portfolio/?category=angular"
                aria-controls="angular"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Angular
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "gatsby" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "gatsby" ? "active show" : "show"}`}
                to="/portfolio/?category=gatsby"
                aria-controls="gatsby"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Gatsby
              </Link>
            </li>
            <li
              role="presentation"
              className={`${category === "flutter" ? "active mb-2" : "mb-2"}`}
            >
              <Link
                className={`${category === "flutter" ? "active show" : "show"}`}
                to="/portfolio/?category=flutter"
                aria-controls="flutter"
                role="tab"
                data-toggle="tab"
                aria-selected="false"
              >
                Flutter
              </Link>
            </li>
          </ul>
          {/* section title ends */}
          <div className="row align-items-start">
            <div className="col-lg-12 m-15px-tb">
              <div className="row">
                {posts.map((post, key) => {
                  const title = post.frontmatter.title || post.fields.slug;
                  return (
                    <div key={key} className="col-sm-4">
                      <div className="blog-grid">
                        <div className="blog-img">
                          {/*<div className="date">{post.frontmatter.date}</div>*/}
                          <Link to={post.fields.slug}>
                            <img
                              src={post.frontmatter.bannerimage}
                              title
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="blog-info">
                          <h5>
                            <Link to={post.fields.slug} itemProp="url">
                              {title.length > 50
                                ? title.substring(0, 50).concat("...")
                                : title}
                            </Link>
                          </h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                post.frontmatter.description.length > 105
                                  ? post.frontmatter.description
                                      .substring(0, 105)
                                      .concat("...")
                                  : post.frontmatter.description ||
                                    post.excerpt,
                            }}
                            itemProp="description"
                          />
                          <div className="btn-bar">
                            <Link
                              className="px-btn-arrow"
                              to={post.fields.slug}
                              itemProp="url"
                            >
                              <span>Read More</span>
                              <i className="arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Portfolio;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/portfolio/**" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
          bannerimage
        }
      }
    }
  }
`;
